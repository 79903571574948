export const Paths = {
  home: "/shop",
  shop: "/",
  category: "/shop/:category",
  cart: "/cart",
  product: "/product",
  productPage: "/product/:name/:id",
  dashboardLogin: "/admin/login",
  admin: "/admin/*",
  adminPath: "/admin",
  dashboardHome: "/home",
  dashboardHome1: "/",
  dashboardOrders: "/orders",
  dashboardProducts: "/products",
  dashboardAddProduct: "/products/addproduct",
  dashboardUpdateProduct: "/products/updateProduct/",
  dashboardUpdateProductPage: "/products/updateProduct/:id",
};
