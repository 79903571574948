export const BASE_URL = "https://shebatime-backend-wuiz.onrender.com/api/v1";

export const Requests = {
  get: {
    getProducts: "/products",
    getProduct: "/products/:id",
    getOrders: "/orders",
  },
  post: {
    sendMessage: "/messages",
    createOrder: "/orders",
    login: "/user/login",
    createProduct: "/products",
  },
  update: {
    updateOrder: "/orders/:id",
    updateProduct: "/products/:id",
  },
  delete: {
    deleteProduct: "/products/:id",
  },
};

export const Sort = {
  descendingOrder: "orders:desc",
  ascendingOrder: "orders:asc",
  descendingCreatedAtOrder: "createdAt:desc",
  ascendingCreatedAtOrder: "createdAt:asc",
};
